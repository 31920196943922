jQuery(function () {
    $(".form-user").focus(function() {
        $('.input-user').addClass('input-group-focus');
    }).blur(function() {
        $('.input-user').removeClass('input-group-focus');
    });

    $(".form-password").focus(function() {
        $('.input-password').addClass('input-group-focus');
    }).blur(function() {
        $('.input-password').removeClass('input-group-focus');
    });
})
